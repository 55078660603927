import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyCpD8iosQCf8E6crS4am7lZw4lbuM71gx4",

  authDomain: "voicewebappreact.firebaseapp.com",

  projectId: "voicewebappreact",

  storageBucket: "voicewebappreact.appspot.com",

  messagingSenderId: "44051818527",

  appId: "1:44051818527:web:d3d599f0a4506a28e98d2a",

  measurementId: "G-ES63JLDMZX",
};

// Initialize Firebase

export default firebase.initializeApp(firebaseConfig);
