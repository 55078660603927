import constants from "./blogsConstants";

export const addBlog = (payload) => {
  return {
      type: constants.ADD_BLOG_ACTION,
      payload
  }
}

export const fetchBlogs = (payload) => {
  return {
      type: constants.GET_ALL_BLOGS,
      payload
  }
}