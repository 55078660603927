export const getUsersList = () =>
  fetch('/.netlify/functions/api/users/fetchAll').then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const updateUserType = ({ id, userType }) =>
  fetch('/.netlify/functions/api/users/updateStatus', {
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, userType })
}).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);
