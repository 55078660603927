import { call, fork, put, takeLatest } from "redux-saga/effects";

import * as restApi from "./login-resources";
import constants from "./login-constants";
import history from "../../history";
import profileConstants from "../Profile/profile-constants";

export function* getLoginData({ payload }) {
  try {
    const response = yield call(restApi.getLoginData, payload);

    yield put({ type: constants.GET_LOGIN_DATA_SUCCESS, payload: response });
    yield put({ type: profileConstants.UPDATE_USER_SUCCESS, payload: response.profile });

    history.push("/user/profile");
  } catch (error) {
    yield put({ type: constants.GET_LOGIN_DATA_FAILURE, payload: error });
  }
}

export function* watchGetLoginData() {
  yield takeLatest(constants.LOGIN_ACTION, getLoginData);
}

export function* resetPassword({ payload }) {
  try {
    const response = yield call(restApi.forgotPassword, payload);
    yield put({ type: constants.RESET_PASSWORD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: constants.RESET_PASSWORD_FAILURE, payload: error });
  }
}

export function* watchResetPassword() {
  yield takeLatest(constants.RESET_PASSWORD, resetPassword);
}

export function* doRegistration({ payload }) {
  try {
    var response = yield call(restApi.doRegistration, payload);
    response = { ...response, name: payload.name, contact: payload.contact };
    yield put({ type: constants.GET_SIGNUP_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: constants.GET_SIGNUP_FAILURE, payload: error });
  }
}

export function* watchDoRegistration() {
  yield takeLatest(constants.SIGNUP_ACTION, doRegistration);
}

export const LoginSagas = [
  fork(watchGetLoginData),
  fork(watchDoRegistration),
  fork(watchResetPassword),
];
