export default {
    ADD_BLOG_ADMIN_ACTION: 'ADD_BLOG_ADMIN_ACTION',
    ADD_BLOG_ADMIN_SUCCESS: 'ADD_BLOG_ADMIN_SUCCESS',
    ADD_BLOG_ADMIN_FAILURE: 'ADD_BLOG_ADMIN_FAILURE',
    FETCH_BLOG_ADMIN_ACTION: 'FETCH_BLOG_ADMIN_ACTION',
    FETCH_BLOG_ADMIN_SUCCESS: 'FETCH_BLOG_ADMIN_SUCCESS',
    FETCH_BLOG_ADMIN_FAILURE: 'FETCH_BLOG_ADMIN_FAILURE',
    DELETE_BLOG_ADMIN_ACTION: 'DELETE_BLOG_ADMIN_ACTION',
    DELETE_BLOG_ADMIN_SUCCESS: 'DELETE_BLOG_ADMIN_SUCCESS',
    DELETE_BLOG_ADMIN_FAILURE: 'DELETE_BLOG_ADMIN_FAILURE',
    UPDATE_BLOG_ADMIN_ACTION: 'UPDATE_BLOG_ADMIN_ACTION',
    UPDATE_BLOG_ADMIN_SUCCESS: 'UPDATE_BLOG_ADMIN_SUCCESS',
    UPDATE_BLOG_ADMIN_FAILURE: 'UPDATE_BLOG_ADMIN_FAILURE',
    RESET_BLOG_SUCCESS: "RESET_BLOG_SUCCESS",
}