export const addBlog = (payload) => 
    fetch('/.netlify/functions/api/admin/blog/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    }).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const fetchBlogs = () => 
    fetch('/.netlify/functions/api/admin/blogs/fetch').then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const deleteBlog = ({ id }) => {
    fetch('/.netlify/functions/api/admin/blog/delete', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({id})
    }).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);  
} 

// export const updateBlogStatus = ({ id, isApproved }) => {
//     console.log("payload: ", { id, isApproved });
//     return fetch('/.netlify/functions/api/admin/blog/updateStatus', {
//         method: 'PATCH',
//         body: JSON.stringify({ id, isApproved }),
//     }).then(res => res.json())
//         .then(res => res.data)
//         .catch((err) => err);
// }

export const updateBlogStatus = (payload) =>
    fetch('/.netlify/functions/api/admin/blog/updateStatus', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    }).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);