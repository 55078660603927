import constants from "./blogsConstants";

const initial_state = {
  blogs: [],
  addedBlogs: null,
}

const blogsSection = (state = initial_state, action) => {
  switch (action.type) {
    case constants.GET_ALL_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload,
      };
    case constants.GET_ALL_BLOGS_FAILURE:
      return state;

    case constants.ADD_BLOG_ACTION:
      return { ...state, addedBlogs: null }

    case constants.ADD_BLOG_SUCCESS:
      return { ...state, addedBlogs: action.payload };
    default:
      return state;
  }
};

export default blogsSection;