import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBlog } from '../blogsActions';
import "./share.css";
import ReactQuill from 'react-quill';
import { convertFileToUrl, generateUniqueFileName } from '../../../../assets/lib/utils/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useUploadImage } from '../../../../assets/lib/hooks/useUploadImage';

const ShareForm = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [formKey, setFormKey] = useState(0);
  const initialBlogsState = {
    title: '',
    desc: '',
    timestamp: new Date(),
    author: {
        id: props.userId,
        name: props.user ? props.user.name : 'anonymous',
        profileImage: props.user?.profileImage || ''
    },
    photo: '',
    likes: 0,
    comments: 0,
    isNew: true,
    isApproved: false,
    tier: "visitor",
  };
  const [blogsState, setBlogsState] = useState(initialBlogsState);
  const [descError, setDescError] = useState(false);
  const formRef = useRef(null);
  const {url, err, uploadImage} = useUploadImage();

  useEffect(()=>{
    if (url) {
      const payload = {
        ...blogsState,
        photo: url,
      };
      props.addBlog(payload);
    }
  }, [url, err])

  useEffect(() => {
    if (props.user && props.user.name) {
      setBlogsState(prevState => ({
        ...prevState,
        author: {
          id: props.userId,
          name: props.user.name,
          profileImage: props.user.profileImage || ''
        }
      }));
    }
  }, [props.user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);

  const handleDescriptionChange = (value) => {
    setBlogsState({ ...blogsState, desc: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setBlogsState({ ...blogsState, photo: file });
      setImagePath(convertFileToUrl(file));
    } else {
      setBlogsState({ ...blogsState, photo: null });
      setImagePath('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!blogsState.desc || blogsState.desc.trim() === '<p><br></p>') {
      setDescError(true);
      return;
    }

    setDescError(false);
    
    const user = props.user;
    if (user) {
      if (blogsState.photo) {
        uploadImage(
          "blogImages",
          `${generateUniqueFileName(props.user.name, blogsState.photo)}`,
          blogsState.photo
        )
      } else {
        props.addBlog(blogsState);
      }
      setSubmissionSuccess(true);
      setBlogsState({
        ...initialBlogsState,
        author: {
          id: props.userId,
          name: props.user ? props.user.name : 'anonymous',
          profileImage: props.user?.profileImage || ''
        },
        timestamp: new Date(),
      });
      setFormKey(prevKey => prevKey + 1);
      setImagePath('');
      setTimeout(() => setSubmissionSuccess(false), 8000);
    } else {
      alert("Please Log In to Create Blog");
    }
  };

  const handleTitleFocus = () => {
    setShowOptions(true);
  };

  return (
    <div className="shareCard">
      <form ref={formRef} className="shareWrapper" onSubmit={handleSubmit}>
        <h2>Share Your Blog</h2>
        <input
          placeholder="Title for your Blog"
          className="shareInput"
          name="title"
          value={blogsState.title}
          onChange={(e) => setBlogsState({ ...blogsState, title: e.target.value })}
          onFocus={handleTitleFocus}
          required
        />
        {showOptions && (
          <div className="shareOptions">
            <h5>Description:</h5>
            <ReactQuill
              theme="snow"
              value={blogsState.desc}
              onChange={handleDescriptionChange}
              style={{ backgroundColor: 'white' }}
            />
            <h5>Add an Image:</h5>
            <div className="imageUpload">
              <input
                key={formKey}
                type="file"
                name="photo"
                onChange={handleImageChange}
                className="shareOption"
                accept="image/*"
              />
              {imagePath && <img alt="Image Preview" src={imagePath} className="imagePreview" />}
            </div>
            {descError && <div className="errorMsg">Description is required.</div>}
          </div>
        )}
        <button className="shareButton" type="submit">
          <FontAwesomeIcon icon={faShare} /> Share
        </button>
        {submissionSuccess && <div className="successMsg"><FontAwesomeIcon icon={faCheckCircle} />{' '}Thank you! Your Submission received. It will be visible post admin-approval.</div>}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileSection.user,
    userId: state.auth.userId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  addBlog: bindActionCreators(addBlog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareForm);