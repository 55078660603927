import React from "react";
import "./main.css";
import Header from "../header/header-component";
import Footer from "../footer/footer-component";
import Content from "../main-content/main-content-component";
import { BrowserRouter as Router } from "react-router-dom";
import history from "../history";
import Headroom from 'react-headroom'

const Main = () => {
  return (
    <div className="main">
      <Router history={history}>
        <Headroom>
          <Header />
        </Headroom>
        <div className="middle-body">
          <Content/>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default Main;
