import Post from "../post/Post";
import Share from "../share/Share";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./feed.css";

import { useEffect } from "react";
import { fetchBlogs } from "../blogsActions"

function Feed(props) {

  useEffect(()=> {
    props.fetchBlogs();
  },[])


  return (
    <div className="feed">
      <div className="feedWrapper">
         <Share />
          {/* {console.log("blogs", props.blogs)} */}
         {props.blogs?.length === 0 && <h1>No Blogs Found</h1>}
         {Object.values(props.blogs)
          .filter(p => p.isApproved) // Filter to only include approved posts
          .map((p) => (
            <Post key={p.id} post={p} />
        ))}
        <div className="gap"></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    blogs: state.blogsSection.blogs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBlogs: bindActionCreators(fetchBlogs, dispatch),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Feed);