import "./post.css";
import DOMPurify from 'dompurify';
import { MdMoreVert } from "react-icons/md";
import { useState } from "react";
import avatar from "../../../../assets/img/user.svg";

export default function Post({ post }) {
  const [like,setLike] = useState(post.likes)
  const [isLiked,setIsLiked] = useState(false)
  const [textValue, setTextValue] = useState((post?.desc && post?.desc.length>1000) ? "... More" : "")
  const [desc,setDesc] = useState((post?.desc && post?.desc.length>1000) ? post?.desc.substring(0, 1000) : post?.desc)

  const sanitizedDescHTML = DOMPurify.sanitize(desc);

  const likeHandler =()=>{
    setLike(isLiked ? like-1 : like+1)
    setIsLiked(!isLiked)
  }

  const descHandler =()=>{
    setDesc(textValue == " Less"? post?.desc.substring(0, 1000) : post?.desc)
    if(textValue==" Less") window.scrollBy(0, -1200);
    setTextValue(textValue == "... More" ? " Less" : "... More")
  }
  // var desc = post?.desc;
  // desc = (desc && desc.length>50)?desc.substring(0, 1000)+"...":desc;

  return (
    <div className="post">
      <div className="postWrapper">
        <div className="postTop">
          <div className="postTopLeft">
            <img
              className="postProfileImg"
              src={post.author.profileImage || avatar }
              alt=""
            />
            <span className="postUsername">
              {post.author.name}
            </span>
            <span className="postDate">{post.date}</span>
          </div>
          <div className="postTopRight">
            <MdMoreVert />
          </div>
        </div>
        <div className="postCenter">
          <h4 className="postText">{post.title}</h4>
          <hr/>
        </div>
        {post.photo? <img className="postImg" src={post.photo} alt="" /> : ""}
        <div className="postCenter">
          <div className="postText postDescription" dangerouslySetInnerHTML={{ __html: sanitizedDescHTML }}></div>
          <a style={{color:"blue"}} onClick={descHandler}>{textValue}</a>
        </div>
        <div className="postBottom">
          <div className="postBottomLeft">
            <img className="likeIcon" src="assets/heart.png" onClick={likeHandler} alt="" />
            <span className="postLikeCounter">{like} likes</span>
          </div>
          <div className="postBottomRight">
            <span className="postCommentText">{post.comment} comments</span>
          </div>
        </div>
      </div>
    </div>
  );
}