export const getUserData = ({ userId }) =>
  fetch(`/.netlify/functions/api/profile/${userId}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    }
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const updateUserProfile = ({ userId, values }) =>
  fetch('/.netlify/functions/api/profile/update', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, values })
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);
