export const addTestimonies = (payload) =>
  fetch('/.netlify/functions/api/testimonies/add', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const fetchTestimonies = () =>
  fetch('/.netlify/functions/api/testimonies/fetch', {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    }
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);
