export const getLoginData = ({ email, password }) => 
  fetch('/.netlify/functions/api/login', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, password})
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const forgotPassword = ({ email }) =>
  fetch('/.netlify/functions/api/forgotPassword', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({email})
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const doRegistration = ({ email, password }) =>
  fetch('/.netlify/functions/api/register', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, password})
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);
