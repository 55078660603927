import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getAllEvents } from "../Events/EventAdmin/eventAdminActions";
import { fetchBlogs } from "../Blogs/blogsActions";
import homeConstants from "./home-constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BsBoxArrowUpRight } from 'react-icons/bs';

const EventsBlogsPanel = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 4;

  useEffect(() => {
    props.getAllEvents();
    props.fetchBlogs();
  }, []);

  const Events = useMemo(() => {
    const events = [];

    if (props.events) {
      Object.values(props.events).forEach(evt => {
        const tier = props.user ? props.user.tier : 'tier1';

        if (homeConstants.displayUserEvents[tier].includes(evt.tier)) {
          events.push(<Link to={"/events/" + evt.id} key={evt.id}>
            <p><BsBoxArrowUpRight /> {evt.title}</p>
          </Link>);
        }
      });
    }
    return events.length ? events : null;
  }, [props.events, props.user]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil((props.blogs ? props.blogs.filter(blog => blog.isApproved).length : 0) / blogsPerPage);

  const currentBlogs = useMemo(() => {
    const startIndex = (currentPage - 1) * blogsPerPage;
    const endIndex = startIndex + blogsPerPage;
    return props.blogs ? props.blogs
      .filter(blog => blog.isApproved)
      .slice(startIndex, endIndex) : [];
  }, [props.blogs, currentPage]);

  return (
    <div>
      <div className="events-container">
        <h5>Upcoming Events</h5>
        <hr />
        <div className="events-content">
          <div>
            {Events || <p><i>No Upcoming Events...</i></p>}
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <h5>Latest Blogs</h5>
        <hr />
        {currentBlogs.length ? currentBlogs.map(blog => (
          <div className="blogs-content" key={blog.id}>
            <p>
              {blog.title} <i> ~{blog.author.name}</i>
              {blog.isNew && <span className="new-tag">New</span>}
              <hr />
            </p>
          </div>
        )) : <p><i>No Blogs...</i></p>}
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.eventSection.events,
    user: state.profileSection.user,
    blogs: state.blogsSection.blogs,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getAllEvents: bindActionCreators(
      getAllEvents,
      dispatch
    ),
    fetchBlogs: bindActionCreators(fetchBlogs, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsBlogsPanel);