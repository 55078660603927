import React, { useEffect } from "react";
import ModalMenu from "./ModalMenu";
import { Button } from "react-bootstrap";
import { changeShowAuthMenu } from "./login-action";
import { connect } from "react-redux";
import { initializeAuth } from "./login-action";

const LoginComponent = (props) => {

  useEffect(()=>{
    fetch('/.netlify/functions/api/login/check').then(res => res.json())
      .then(res => {
        console.log(res)
        props.initializeAuth(res.data)
      })
      .catch((err) => err);
  }, [])

  const handleShow = () => {
    props.changeShowAuthMenu();
  };

  return (
    <React.Fragment>
      <Button
        className="header-nav-link header-nav-button"
        variant="outline-primary"
        onClick={handleShow}
      >
        Login
      </Button>
      <ModalMenu />
    </React.Fragment>
  );
};

export default connect(null, {
  changeShowAuthMenu,
  initializeAuth,
})(LoginComponent);
