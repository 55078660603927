import React, { Fragment, useEffect, useReducer, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AdminSectionTemplate from "../../../../assets/lib/components/admin-section-template/admin-section-template-component";
import "../../../../assets/lib/components/forms/admin-section-forms/admin-section-forms.css";
import { addBlog, deleteBlog, fetchBlogs, updateBlogStatus } from "./blogs-admin-actions";
import './blogs-admin-component.css';
import BlogsAdminRequestsComponent from "./blogs-admin-requests-component";
import { convertFileToUrl, generateUniqueFileName } from "../../../../assets/lib/utils/utility";
import { useUploadImage } from "../../../../assets/lib/hooks/useUploadImage";

const blogsReducer = (state, action) => {
    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.value,
            };
        case "desc":
            return {
                ...state,
                desc: action.value,
            };
        case "photo":
            return {
                ...state,
                photo: action.target.files[0],
            };
        default:
            return state;
    }
};

const Blogs = (props) => {
    const [view, setView] = useState('view');
    const [descError, setDescError] = useState(false);
    const [imagePath, setImagePath] = useState('');
    const [formKey, setFormKey] = useState(0);
    const {url, err, uploadImage} = useUploadImage();
    // console.log("props", props);
    const [blogsState, dispatch] = useReducer(blogsReducer, {
        title: '',
        desc: '',
        timestamp: new Date(),
        author: {
            id: props.userId,
            name: props.user.name,
            profileImage: props.user?.profileImage || ''
        },
        likes: 0,
        photo: '',
        comments: 0,
        isNew: true,
        isApproved: false,
        tier: "visitor", /* (visitor, inmate, admin) */
    });

    useEffect(()=>{
        if (url) {
            const payload = {
                ...blogsState,
                photo: url,
            };
            props.addBlog(payload);
        }
    }, [url, err])

    const handleInput = (evt) => {
        const { name, type, value } = evt.target;
        if (type === "file") {
            handleImageChange(evt);
        } else {
            dispatch({ type: name, value });
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if (!blogsState.desc || blogsState.desc.trim() === '<p><br></p>') { // ReactQuill inserts <p><br></p> for empty content
            setDescError(true); // Show error message
            return; // Prevent form submission
        }

        setDescError(false); // Hide error message

        const user = props.user;
        // console.log("user", user, "\nblogsState", blogsState)
        if (user) {
            if (blogsState.photo) {
                uploadImage(
                    "blogImages",
                    `${generateUniqueFileName(props.user.name, blogsState.photo)}`,
                    blogsState.photo
                )
            } else {
                console.log("No image to upload, blogState: ", blogsState);
                props.addBlog(blogsState);
            }
            dispatch({ type: 'desc', value: '' });
            dispatch({ type: 'timestamp', value: new Date() });
            setFormKey(prevKey => prevKey + 1); // Increment formKey to reset file input
            setImagePath('');
        }
        else {
            alert("Please Log In to Create Blog")
        }
    }

    const handleViewChange = (evt) => {
        setView(evt.target.dataset.viewType);
    }

    const handleImageChange = (evt) => {
        if (evt.target.files && evt.target.files.length > 0) {
            const file = evt.target.files[0];
            dispatch({ type: 'photo', target: { files: [file] } });
            if (file) {
                const fileUrl = convertFileToUrl(file);
                setImagePath(fileUrl);
            }
        } else {
            // Handle case where no file is selected
            dispatch({ type: 'photo', target: { files: [] } });
            setImagePath('');
        }
    };

    const handleDescriptionChange = (value) => {
        dispatch({ type: 'desc', value });
    }

    useEffect(() => {
        setView('view')
        props.fetchBlogs()
    }, [props.addedBlogs]);

    return (
        <AdminSectionTemplate className="admin-blogs-container">
            <div className="admin-blogs-tabs-panel">
                <div data-view-type="view" onClick={handleViewChange} className="admin-blogs-tab">View Approved</div>
                <div data-view-type="add" onClick={handleViewChange} className="admin-blogs-tab">Add New</div>
                <div data-view-type="requests" onClick={handleViewChange} className="admin-blogs-tab">All Requests</div>
            </div>
            <div className="view-panel">
                <div className="admin-blogs-content-container">
                    {view === 'view' && <Fragment>
                        <div className="admin-blogs-content-item list-heading">
                            <div>Title</div>
                        </div>
                        {props.blogs && props.blogs.length > 0 ? props.blogs.filter(blog => blog.isApproved).map(blog =>
                            <div className="admin-blogs-content-item">
                                <h6>{blog.title}</h6>
                                <div>
                                    <button>View</button>
                                    <button>Edit</button>
                                    <button>Delete</button>
                                </div>
                            </div>) : "No approved blogs found."}
                    </Fragment>
                    }
                    {view === 'add' &&
                        <form onSubmit={handleSubmit} className="admin-form">
                            <label htmlFor="title">Title: </label>
                            <input onChange={handleInput} name="title" type="text" required />
                            <label htmlFor="desc">Description: </label>
                            <div>
                                <ReactQuill value={blogsState.desc} onChange={handleDescriptionChange} style={{ backgroundColor: 'white' }} required />
                                <div>
                                    <p>
                                        <sup>**</sup>Please enter the text in the format (paragraph) you
                                        want to see in the blogs. You can also expand the text area
                                        by dragging the bottom right corner.{" "}
                                    </p>
                                </div>
                                <label htmlFor="photo">Photo: </label>
                                <input onChange={handleInput} key={formKey} name="photo" type="file" accept="image/*" />
                                {imagePath && <img alt="Image Preview" src={imagePath} style={{ height: '100px' }} />}
                            </div>
                            {descError && <div style={{ color: 'red' }}>Description is required.</div>}
                            <input className="admin-form--submit" type="submit" value="Submit" />
                        </form>
                    }
                    {view === 'requests' &&
                        <BlogsAdminRequestsComponent />
                    }
                </div>
            </div>
        </AdminSectionTemplate>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.profileSection.user,
        userId: state.auth.userId,
        blogs: state.blogsAdminSection.blogs,
        addedBlogs: state.blogsAdminSection.addedBlogs
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBlog: bindActionCreators(addBlog, dispatch),
        fetchBlogs: bindActionCreators(fetchBlogs, dispatch),
        deleteBlog: bindActionCreators(deleteBlog, dispatch),
        updateBlogStatus: bindActionCreators(updateBlogStatus, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);