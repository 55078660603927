export default {
  AUTH_INIT: "AUTH_INIT",
  CHANGE_SHOW: "CHANGE_SHOW",
  CLOSE_AUTHMENU: "CLOSE_AUTHMENU",
  CHANGE_VIEW: "CHANGE_VIEW",
  LOGIN_ACTION: "LOGIN_ACTION",
  ANONYMOUS_LOGIN_ACTION: "ANONYMOUS_LOGIN_ACTION",
  DELETE_ANONYMOUS_LOGIN_ACTION: "DELETE_ANONYMOUS_LOGIN_ACTION",
  DELETE_ANONYMOUS_LOGIN_SUCCESS: "DELETE_ANONYMOUS_LOGIN_SUCCESS",
  DELETE_ANONYMOUS_LOGIN_FAILURE: "DELETE_ANONYMOUS_LOGIN_FAILURE",
  GET_LOGIN_DATA_SUCCESS: "GET_LOGIN_DATA_SUCCESS",
  GET_LOGIN_DATA_FAILURE: "GET_LOGIN_DATA_FAILURE",
  SIGNUP_ACTION: "SIGNUP_ACTION",
  GET_SIGNUP_SUCCESS: "GET_SIGNUP_SUCCESS",
  GET_SIGNUP_FAILURE: "GET_SIGNUP_FAILURE",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  SIGNOUT_ACTION: "SIGNOUT_ACTION",
};
