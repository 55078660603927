export const submitQuery = (queryInfo) =>
  fetch('/.netlify/functions/api/queries/create', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(queryInfo)
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);;
