import { useState } from "react"

export const useUploadImage = () => {
    const [url, setUrl] = useState();
    const [err, setErr] = useState();

    const uploadImage = (ref, title, image) => {
        fetch('/.netlify/functions/api/image/upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ref, title, image })
        }).then(res => res.json())
        .then(res => {
            if(res.data){
                setUrl(res.data)
            } else if(res.error){
                setErr(res.error)
            }
        })
        .catch((err) => setErr(err));
    }

    return {url, err, uploadImage};
}
