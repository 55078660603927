export const registerUser = (payload) =>
  fetch('/.netlify/functions/api/bulk/register', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
}).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);
