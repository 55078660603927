import { call, fork, put, takeLatest } from "redux-saga/effects";
import constants from "./blogsConstants";
import * as restApi from "./blogsResources.js";

export function* fetchBlogs () {
  try {
      const response = yield call(restApi.fetchBlogs);

      yield put({ type: constants.GET_ALL_BLOGS_SUCCESS, payload: response });
  } catch (error) {
      yield put({ type: constants.GET_ALL_BLOGS_FAILURE, payload: error.message});
  }
}

export function* watchFetchBlogs() {
  yield takeLatest(constants.GET_ALL_BLOGS, fetchBlogs);
}

function* addBlog (action) {
  try {
      const response = yield call(restApi.addBlog, action.payload);

      yield put({ type: constants.ADD_BLOG_SUCCESS, payload: { id: response.id, ...action.payload }});
  } catch (error) {
      yield put({ type: constants.ADD_BLOG_FAILURE, payload: error.message});
  }
}

function* watchAddBlogs () {
  yield takeLatest(constants.ADD_BLOG_ACTION, addBlog)
}

export const blogsSagas = [
    fork(watchFetchBlogs),
    fork(watchAddBlogs)
];
