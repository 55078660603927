export default {
  FETCH_QUERIES_ACTION: "FETCH_QUERIES_ACTION",
  FETCH_QUERIES_SUCCESS: "FETCH_QUERIES_SUCCESS",
  FETCH_QUERIES_FAILURE: "FETCH_QUERIES_FAILURE",
  UPDATE_QUERY_STATUS: "UPDATE_QUERY_STATUS",
  UPDATE_QUERY_STATUS_SUCCESS: "UPDATE_QUERY_STATUS_SUCCESS",
  UPDATE_QUERY_STATUS_FAILURE: "UPDATE_QUERY_STATUS_FAILURE",
  DELETE_QUERY: "DELETE_QUERY",
  DELETE_QUERY_SUCCESS: "DELETE_QUERY_SUCCESS",
  DELETE_QUERY_FAILURE: "DELETE_QUERY_FAILURE",
};
