export const fetchQueriesList = () =>
  fetch('/.netlify/functions/api/queries/fetchAll').then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const updateQueryStatus = ({ id, status }) =>
  fetch('/.netlify/functions/api/queries/updateStatus', {
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, status })
}).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const deleteQuery = ({ id }) => fetch('/.netlify/functions/api/queries/delete', {
  method: 'DELETE',
  headers: {
      'Content-Type': 'application/json',
  },
  body: JSON.stringify({id})
}).then(res => res.json())
  .then(res => res.data)
  .catch((err) => err);
