export const fetchQnAList = () =>
  fetch('/.netlify/functions/api/qna/fetchAll').then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const deleteQnA = ({ id }) => fetch('/.netlify/functions/api/qna/delete', {
  method: 'DELETE',
  headers: {
      'Content-Type': 'application/json',
  },
  body: JSON.stringify({id})
}).then(res => res.json())
  .then(res => res.data)
  .catch((err) => err);

export const updateQnAStatus = ({ id, status }) =>
  fetch('/.netlify/functions/api/qna/updateStatus', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, status })
    }).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const addQnA = (payload) => fetch('/.netlify/functions/api/qna/create', {
  method: 'POST',
  headers: {
      'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload)
}).then(res => res.json())
  .then(res => res.data)
  .catch((err) => err);
