export default {
  FETCH_QNAS_ACTION: "FETCH_QNAS_ACTION",
  FETCH_QNAS_SUCCESS: "FETCH_QNAS_SUCCESS",
  FETCH_QNAS_FAILURE: "FETCH_QNAS_FAILURE",
  UPDATE_QNA_STATUS: "UPDATE_QNA_STATUS",
  UPDATE_QNA_STATUS_SUCCESS: "UPDATE_QNA_STATUS_SUCCESS",
  UPDATE_QNA_STATUS_FAILURE: "UPDATE_QNA_STATUS_FAILURE",
  ADD_QNA: "ADD_QNA",
  ADD_QNA_SUCCESS: "ADD_QNA_SUCCESS",
  RESET_QNA_SUCCESS: "RESET_QNA_SUCCESS",
  ADD_QNA_FAILURE: "ADD_QNA_FAILURE",
  DELETE_QNA: "DELETE_QNA",
  DELETE_QNA_SUCCESS: "DELETE_QNA_SUCCESS",
  DELETE_QNA_FAILURE: "DELETE_QNA_FAILURE",
};
