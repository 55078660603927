export const addBlog = (payload) => 
    fetch('/.netlify/functions/api/blog/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    }).then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);

export const fetchBlogs = () => 
    fetch('/.netlify/functions/api/blogs/fetch').then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);
