export const addDynamicContent = (payload) =>
  fetch('/.netlify/functions/api/dynamicContent/create', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  }).then(res => res.json())
    .then(res => res.data)
    .catch((err) => err);

export const fetchDynamicContent = () =>
  fetch('/.netlify/functions/api/dynamicContent/fetchAll').then(res => res.json())
        .then(res => res.data)
        .catch((err) => err);
