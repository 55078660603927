export const convertFileToUrl = (file) => {
    return URL.createObjectURL(file);
}

export const generateUniqueFileName = (user, file) => {
    const name = user.replace(/\s+/g, ''); // Remove spaces from user name
    const timestamp = new Date().getTime(); // Current time in milliseconds
    const randomString = Math.random().toString(36).substring(2, 15); // A random string
    const originalFileName = file.name.replace(/\.[^/.]+$/, ""); // Remove extension from original file name
    const extension = file.name.split('.').pop(); // Get the file extension

    return `${name}_${timestamp}_${randomString}_${originalFileName}.${extension}`;
};